import {evolve, curry} from 'ramda';
import {replace} from 'utils/objects';
import {over} from 'utils/lenses';
import services from 'services';
import initState from './state';
import {parseNullableNumber} from 'utils/fields';
import {transform} from 'ol/proj';
import {aggregateColors} from './constants';
import {fullAddress} from 'utils/buildings';
import * as encDicts from 'dicts/encounters';

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const parseNullableJson = deflt => str => str !== '' ? JSON.parse(str) : deflt;

export const parseUrlQuery = query => {
	const vals = evolve(
		{
			z: Number,
			x: Number,
			y: Number,
			maxYear: parseNullableNumber,
			minYear: parseNullableNumber,
			activeCallPoolId: parseNullableNumber,
			selectionIds: str => (str ? str.split(',').map(s => Number(s)) : []),
			areasLayer: parseNullableJson(true),
			buildingsLayer: parseNullableJson(true),
			callPoolsLayer: parseNullableJson(true),
			groundwaterAreasLayer: parseNullableJson(false),
			propertyLinesLayer: parseNullableJson(false),
		},
		query,
	);
	return {mapQuery: replace(vals, initState.mapQuery)};
};

export const formatUrlQuery = mapQuery => {
	return mapQuery.selectionIds
		? over(['selectionIds'], selectionIds => selectionIds.join(','), mapQuery)
		: mapQuery;
};

export const formatMapFilterFormInitValues = mapQuery => ({
	encounterState: mapQuery.encounterState,
	minYear: mapQuery.minYear,
	maxYear: mapQuery.maxYear,
	areaType: mapQuery.areaType,
	aggregateType: mapQuery.aggregateType,
});

const aggregateFormatters = {
	encounter_coverage: val => intl.formatNumber(val, {style: 'percent'}),
	encounter_coverage_3m: val => intl.formatNumber(val, {style: 'percent'}),
	deals_count: val => intl.formatNumber(val, {style: 'decimal'}),
	building_age: val =>
		intl.formatNumber(val, {style: 'decimal', maximumFractionDigits: 0}),
};

export const formatAggregateValue = curry((aggrName, value) =>
	aggregateFormatters[aggrName] ? aggregateFormatters[aggrName](value) : '',
);

export const formatCallPoolAreaFormInitValues = (callPoolArea, teams) => {
	return {
		manufacturingYearEnd: callPoolArea.manufacturingYearEnd,
		manufacturingYearStart: callPoolArea.manufacturingYearStart,
		excludeUsers: callPoolArea.excludeUsers,
	};
};

export const formatCustomAreaCreatorFormOutput = (form, area) => {
	area.coordinates.forEach(c => {
		c.forEach(xy => {
			const xyNew = transform(xy, 'EPSG:3857', 'EPSG:4326');
			xy[0] = xyNew[0];
			xy[1] = xyNew[1];
		});
	});

	return {
		area,
		teams: form.teams.map(t => ({id: t})),
		title: form.title,
	};
};

export const formatCallPoolCreatorInitValues = (
	activeCallPool,
	teams,
	products,
	smartDataOptions = [],
) => {
	let otherInstalled = null;
	let otherInstalledOrganization = null;
	let otherInstalledProducts = [];

	if (activeCallPool.meta.otherInstalled) {
		otherInstalled = true;
		if (activeCallPool.meta.otherInstalled.organizationId) {
			otherInstalledOrganization = activeCallPool.meta.otherInstalled.organizationId;
		}
		if (activeCallPool.meta.otherInstalled.products) {
			otherInstalledProducts = activeCallPool.meta.otherInstalled.products;
		}
	}

	const smartData = activeCallPool.meta.smartData;
	const allSmartData =
		!smartData ||
		!smartData.length ||
		(smartData.length >= smartDataOptions.length && smartDataOptions.length > 0)
			? true
			: false;

	return {
		title: activeCallPool.title,
		type: activeCallPool.type,
		showBuildingProductStates: activeCallPool.meta.showBuildingProductStates,
		autoCall: activeCallPool.meta.autoCall || false,
		fullyAutoCall: activeCallPool.meta.fullyAutoCall || false,
		noSwedish: activeCallPool.noSwedish,
		onlySwedish: activeCallPool.meta.onlySwedish || false,
		project: activeCallPool.meta.project || false,
		teams: activeCallPool.teams.map(t => t.id),
		products: activeCallPool.meta.products
			? products.reduce((obj, product) => {
					obj[product.id] = activeCallPool.meta.products.includes(product.id);
					return obj;
			  }, {})
			: {},
		allProducts:
			!activeCallPool.meta.products ||
			(!!activeCallPool.meta.products && !activeCallPool.meta.products.length),
		blockedSeniorIds: activeCallPool.meta.blockedSeniorIds
			? activeCallPool.meta.blockedSeniorIds.reduce((obj, item) => {
					return {
						...obj,
						[item]: true,
					};
			  }, {})
			: [],
		buildingTypes: activeCallPool.meta.buildingTypes || [],
		heatingTypes: activeCallPool.meta.heatingTypes || [],
		heatSources: activeCallPool.meta.heatSources || [],
		newCallPool: activeCallPool.meta.newCallPool || false,
		oldOffers: activeCallPool.meta.oldOffers || false,
		otherInstalled,
		otherInstalledOrganization,
		otherInstalledProducts,
		smartData: activeCallPool.meta.smartData
			? activeCallPool.meta.smartData.reduce((obj, item) => {
					return {
						...obj,
						[item]: true,
					};
			  }, {})
			: [],
		allSmartData,
		blackListedBuildingTagIds: activeCallPool.meta?.blackListedBuildingTagIds || [],
		whiteListedBuildingTagIds: activeCallPool.meta?.whiteListedBuildingTagIds || [],
		callableBuildingStates: activeCallPool.meta?.callableBuildingStates || [],
		excludeUsers: activeCallPool.meta?.excludeUsers || [],
		defaultManufacturingYearStart: activeCallPool.meta?.defaultManufacturingYearStart
			? Number(activeCallPool.meta?.defaultManufacturingYearStart)
			: null,
		defaultManufacturingYearEnd: activeCallPool.meta?.defaultManufacturingYearEnd
			? Number(activeCallPool.meta?.defaultManufacturingYearEnd)
			: null,
		order: activeCallPool?.meta?.order || '',
	};
};

export const formatCallPoolCreatorOutput = (form, smartDataOptions = []) => {
	return {
		title: form.title,
		noSwedish: form.noSwedish,
		teams: form.teams.map(t => ({id: t})),
		type: form.type,
		meta: {
			autoCall: form.autoCall,
			fullyAutoCall: form.fullyAutoCall,
			onlySwedish: form.onlySwedish,
			project: form.project,
			products: Object.keys(form.products)
				.filter(k => form.products[k] === true)
				.map(k => Number(k)),
			buildingTypes: form.buildingTypes,
			heatingTypes: form.heatingTypes,
			heatSources: form.heatSources,
			newCallPool: form.newCallPool,
			oldOffers: form.oldOffers,
			otherInstalled: form.otherInstalled && {
				organizationId: form.otherInstalledOrganization,
				products: form.otherInstalledProducts,
			},
			showBuildingProductStates: form.showBuildingProductStates,
			blockedSeniorIds: Object.keys(form.blockedSeniorIds)
				.filter(k => form.blockedSeniorIds[k] === true)
				.map(k => Number(k)),
			// if all opts are picked from smartData send empty array
			smartData:
				Object.values(form.smartData).filter(s => s === true).length >=
				smartDataOptions.length
					? []
					: Object.keys(form.smartData)
							.filter(k => form.smartData[k] === true)
							.map(k => Number(k)),
			blackListedBuildingTagIds: form.blackListedBuildingTagIds || [],
			whiteListedBuildingTagIds: form.whiteListedBuildingTagIds || [],
			callableBuildingStates: form.callableBuildingStates || [],
			excludeUsers: form.excludeUsers || [],
			defaultManufacturingYearStart: form.defaultManufacturingYearStart
				? Number(form.defaultManufacturingYearStart)
				: null,
			defaultManufacturingYearEnd: form.defaultManufacturingYearEnd
				? Number(form.defaultManufacturingYearEnd)
				: null,
			order: form.order || '',
		},
	};
};

export const getAggregateColor = (aggregate, aggregateType) => {
	switch (aggregateType) {
		case 'encounter_coverage': {
			if (aggregate > 38) {
				return aggregateColors.red;
			} else if (aggregate > 31) {
				return aggregateColors.redLight;
			} else if (aggregate > 25) {
				return aggregateColors.orange;
			} else if (aggregate > 19) {
				return aggregateColors.yellow;
			} else if (aggregate > 12) {
				return aggregateColors.greenYellow;
			} else {
				return aggregateColors.green;
			}
		}

		case 'encounter_coverage_3m': {
			if (aggregate > 38) {
				return aggregateColors.red;
			} else if (aggregate > 31) {
				return aggregateColors.redLight;
			} else if (aggregate > 25) {
				return aggregateColors.orange;
			} else if (aggregate > 19) {
				return aggregateColors.yellow;
			} else if (aggregate > 12) {
				return aggregateColors.greenYellow;
			} else {
				return aggregateColors.green;
			}
		}

		case 'deals_count': {
			if (aggregate > 25) {
				return aggregateColors.red;
			} else if (aggregate > 13) {
				return aggregateColors.redLight;
			} else if (aggregate > 8) {
				return aggregateColors.orange;
			} else if (aggregate > 4) {
				return aggregateColors.yellow;
			} else if (aggregate > 1) {
				return aggregateColors.greenYellow;
			} else {
				return aggregateColors.green;
			}
		}

		case 'building_age': {
			if (aggregate < 45) {
				return aggregateColors.red;
			} else if (aggregate < 49) {
				return aggregateColors.redLight;
			} else if (aggregate < 52) {
				return aggregateColors.orange;
			} else if (aggregate < 55) {
				return aggregateColors.yellow;
			} else if (aggregate < 58) {
				return aggregateColors.greenYellow;
			} else {
				return aggregateColors.green;
			}
		}

		default:
			return 'rgba(0,255,0,0.5)';
	}
};

export const getAggregateByType = (aggregates, aggregateType) => {
	if (!aggregates) return null;

	switch (aggregateType) {
		case 'encounter_coverage': {
			const {encounterCoverage} = aggregates;
			return {
				encounterCoverage,
				color: getAggregateColor(encounterCoverage, aggregateType),
			};
		}

		case 'encounter_coverage_3m': {
			const {encounterCoverage3m} = aggregates;
			return {
				encounterCoverage3m,
				color: getAggregateColor(encounterCoverage3m, aggregateType),
			};
		}

		case 'deals_count': {
			const {salesCount} = aggregates;
			return {
				salesCount,
				color: getAggregateColor(salesCount, aggregateType),
			};
		}

		case 'building_age': {
			const {buildingAge} = aggregates;
			const _buildingAge = buildingAge ? parseFloat(buildingAge) : null;
			return {
				buildingAge: _buildingAge,
				color: getAggregateColor(_buildingAge, aggregateType),
			};
		}

		default:
			return null;
	}
};

export const getSelectionTitle = ({selection, selectionType}) => {
	if (!selection) return null;
	else if (selectionType === 'area') return selection.title;
	else if (selectionType === 'building') return fullAddress(selection);
	else return null;
};

export const getEncounterStateTitle = state => {
	switch (state) {
		case 'takeContact':
			return 'Not done';
		case 'installed':
			return 'Done';
		default:
			return encDicts.state[state] || 'Unknown';
	}
};

export const formatProducts = ({products, activeOrganizationId}) => {
	// drop products from customer orgs and "other" type products
	const _products = products.filter(
		p =>
			p.organizations.find(o => !o.meta || !o.meta.customerOrganization) &&
			p.type !== 'other',
	);

	return {
		activeOrganizationProducts: _products.filter(p =>
			p.organizations.find(o => o.id === activeOrganizationId),
		),
		otherOrganizationProducts: _products.filter(
			p => !p.organizations.find(o => o.id === activeOrganizationId),
		),
	};
};

export const addOrRemoveToSelection = (currSelection, selectionId) =>
	currSelection.includes(selectionId)
		? currSelection.filter(s => s !== selectionId)
		: [...currSelection, selectionId];

export const formatFetchableSelectionIds = selectionIds => selectionIds.join(',');
